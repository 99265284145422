/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@use 'base-styles/base-styles';
@use 'base-styles/themes/dg' as dg-theme;

.reinstate {
  @include dg-theme.dg();
}

.grecaptcha-badge {
  visibility: hidden;
}
